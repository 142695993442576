@import "main.css";
.col-lg-6.mx-auto .lead.p-4 p i {
  font-size: 0.65rem;
}

footer{color : white !important
}

div > ul >li >span{color : white !important
}
div > ul >li > a{color : white !important
}


.px-2.px-lg-4.py-2.bg-sixthColor {
    display: none;
}